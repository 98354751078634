<template>
  <div class="wrapper">
    <div v-if="isLoading">
      <ClassificationTaskDocumentViewerSkeleton />
    </div>
    <div v-else>
      <div v-if="filePathUrl">
        <file-viewer class="viewer-container" :url="`${filePathUrl}#navpanes=0&scrollbar=0&view=Fit`" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import ClassificationTaskDocumentViewerSkeleton from './ClassificationTaskDocumentViewerSkeleton';
import { FileViewer } from '@clarityo/ui-components';

export default {
  components: {
    FileViewer,
    ClassificationTaskDocumentViewerSkeleton,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
    },
  },

  setup(props) {
    const filePathUrl = computed(() => props.document?.filePathUrl);

    return {
      filePathUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

.wrapper {
  width: 100%;
}

.viewer-container {
  width: 100%;
  height: auto;
  aspect-ratio: $a4-ratio-viewer;
  margin: auto;
}

.viewer-container iframe {
  width: 100%;
  height: 100%;
}
</style>
