<template>
  <div>
    <div>
      <div class="d-flex justify-content-between">
        <div class="placeholder bg-secondary source-and-username"></div>
        <div class="placeholder bg-secondary file-name"></div>
      </div>
      <div class="mt-2 d-flex justify-content-between w-100">
        <div class="placeholder bg-secondary supplier"></div>
        <div class="placeholder bg-secondary type"></div>
        <div class="placeholder bg-secondary document-number"></div>
      </div>
      <div class="mt-2 d-flex justify-content-between w-100">
        <div class="placeholder bg-secondary has-declaration"></div>
      </div>
      <div class="mt-2 w-100">
        <div class="placeholder bg-secondary file-viewer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

// first row
.source-and-username {
  width: 200px;
  height: 20px;
}

.file-name {
  width: 151px;
  height: 14px;
}

// second row
.supplier {
  display: flex;
  flex-grow: 2;
  height: 32px;
}

.type {
  display: flex;
  flex-grow: 1;
  margin: 0 0.75rem;
  height: 32px;
}

.document-number {
  display: flex;
  flex-grow: 1;
  height: 32px;
}

// 3rd row
.has-declaration {
  width: 165px;
  height: 14px;
}
// 4rd row
.file-viewer {
  width: 100%;
  height: 80vh;
}
</style>
