<template>
  <div class="wrapper">
    <div v-if="isLoading">
      <ClassificationTaskDocumentPanelSkeleton />
    </div>
    <div v-else>
      <div class="row">
        <div class="d-flex">
          <span class="col fontSize">
            <strong>{{ fileName }}</strong>
            <el-tooltip
              v-if="document.replicateOf"
              class="box-item fontSize"
              effect="dark"
              :content="$t('document.validation.documentNumber.replicatedDocument')"
              placement="top"
            >
              <DocumentDuplicate class="duplicate-document-icon" />
            </el-tooltip>
          </span>
          <div>
            <el-tooltip
              class="box-item fontSize"
              effect="dark"
              :content="$t('document.documentsOverview.classificationTasks.document.createdAt') + formattedCreatedAt"
              placement="top"
            >
              <div>
                <span class="col fontSize">{{ source }}</span>
                <DotIcon class="mx-2" />
                <span class="col fontSize">{{ username }}</span>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div :class="hasSupplierErrorFlag ? `error-border` : ''">
            <el-select
              :value="document.supplierId"
              clearable
              filterable
              size="mini"
              class="field-size my-4"
              :placeholder="$t('document.documentsOverview.classificationTasks.document.fields.supplier')"
              @input="updateSupplierId"
            >
              <el-option
                v-for="supplierOption in suppliers"
                :key="supplierOption.id"
                v-model="supplierOption.id"
                :label="`${supplierOption.name} - ${$t('commons.companyNumber')} ${supplierOption.number}`"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-3 p-0">
          <div :class="hasTypeErrorFlag ? `error-border` : ''">
            <el-select
              :value="document.type"
              clearable
              filterable
              size="mini"
              class="field-size my-4"
              :placeholder="$t('document.documentsOverview.classificationTasks.document.fields.docType')"
              @input="updateDocType"
            >
              <el-option
                v-for="_type in documentsTypes"
                :key="_type"
                :value="_type"
                :label="$t(`document.exports.schema.type.fullName.${_type}`)"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-3">
          <div style="position: relative">
            <el-input
              :value="document.documentNumber"
              :disabled="document.replicateOf ? true : false"
              class="field-size my-4"
              type="text"
              :placeholder="$t('document.documentsOverview.classificationTasks.document.fields.docNumber')"
              @input="onChangeDocumentNumber"
            />
            <div>
              <small v-if="isDocumentNumberAlreadyExists" class="el-form-item__error error-position">
                <DocumentAlreadyExist
                  :document-type="document.type"
                  :is-document-number-already-exists="isDocumentNumberAlreadyExists"
                  :is-replicate-of="!!document.replicateOf"
                  @on-show-document="showAlreadyExistDocument = true"
                  @on-toggle-replicate-of="() => $emit('on-toggle-replicate-of')"
                />
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-self-center">
        <label class="mb-4" :style="'color:' + hasDeclarationColor">{{
          $t('document.documentsOverview.classificationTasks.document.hasDeclaration.title')
        }}</label>
        <div>
          <div :class="hasDeclarationErrorFlag ? `error-border` : ''">
            <el-radio-group
              :value="String(hasDeclaration)"
              :disabled="isHasDeclarationDisabled"
              @input="onChangeHasDeclaration"
            >
              <el-radio class="mx-2" label="true" size="large">
                <span class="mx-1">
                  {{ $t('document.documentsOverview.classificationTasks.document.hasDeclaration.yes') }}
                </span>
              </el-radio>
              <el-radio class="mx-2" label="false" size="large">
                <span class="mx-1">
                  {{ $t('document.documentsOverview.classificationTasks.document.hasDeclaration.no') }}
                </span></el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="filePathUrl">
          <file-viewer class="viewer-container" :url="`${filePathUrl}#navpanes=0&scrollbar=0`" />
        </div>
      </div>
    </div>
    <DocumentModal
      v-if="showAlreadyExistDocument"
      visible
      :document-id="alreadyExistsDocumentWithDocumentNumber?.id"
      @close="showAlreadyExistDocument = false"
    />
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref } from 'vue';
import { FileViewer } from '@clarityo/ui-components';
import { DotIcon, DocumentDuplicate } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import typography from '@/stylesheets/scss/typography.module.scss';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { DocumentModal } from '@/modules/documentModal';
import ClassificationTaskDocumentPanelSkeleton from './ClassificationTaskDocumentPanelSkeleton';
import DocumentAlreadyExist from './DocumentAlreadyExist';

export default {
  components: {
    FileViewer,
    DotIcon,
    DocumentDuplicate,
    DocumentModal,
    ClassificationTaskDocumentPanelSkeleton,
    DocumentAlreadyExist,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    suppliers: {
      type: Array,
      default() {
        return [];
      },
    },
    documentsTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
    },
    hasDeclarationErrorFlag: {
      type: Boolean,
      default: false,
    },
    hasSupplierErrorFlag: {
      type: Boolean,
      default: false,
    },
    hasTypeErrorFlag: {
      type: Boolean,
      default: false,
    },
    alreadyExistsDocumentWithDocumentNumber: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: [
    'on-supplier-changed',
    'on-document-type-changed',
    'on-document-number-changed',
    'on-has-declaration-changed',
    'on-toggle-replicate-of',
  ],
  setup(props, { emit }) {
    const { $i18n } = getCurrentInstance().proxy;
    const docType = computed(() => props.document.docType);
    const updateDocType = (e) => {
      emit('on-document-type-changed', e);
    };

    const onChangeDocumentNumber = (e) => {
      emit('on-document-number-changed', e);
    };

    const updateSupplierId = (e) => {
      emit('on-supplier-changed', e);
    };

    const onChangeHasDeclaration = (e) => {
      const booleanResults = e === 'true';
      emit('on-has-declaration-changed', booleanResults);
    };

    const source = computed(() => {
      if (props.document?.source === 'email') {
        return $i18n.t('document.documentsOverview.classificationTasks.document.email');
      } else if (props.document?.source === 'folder') {
        return $i18n.t('document.documentsOverview.classificationTasks.document.folder');
      }

      return '';
    });

    const formattedCreatedAt = computed(() => formatDate(props.document?.createdAt));

    const hasDeclaration = computed(() => props.document?.hasDeclaration);

    const filePathUrl = computed(() => props.document?.filePathUrl);

    function extractFileNameFromPath(filePath = '') {
      const filenameWithExtension = filePath?.split('/').pop();
      return filenameWithExtension;
    }

    const fileName = computed(() => extractFileNameFromPath(props.document?.filePath));

    function formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString($i18n.locale, options.dateAndTime) : '';
    }

    const TYPES_WITH_HARD_CODED_DECLARATION = [
      DOCUMENT_TYPES.RECONCILIATION_STATEMENT,
      DOCUMENT_TYPES.AGED_DEBTORS_REPORT,
      DOCUMENT_TYPES.OTHER,
    ];

    const isHasDeclarationDisabled = computed(
      () => !props.document.type || TYPES_WITH_HARD_CODED_DECLARATION.includes(props.document.type)
    );

    const hasDeclarationColor = computed(() =>
      isHasDeclarationDisabled.value ? typography.typographyDisabled : typography.typographyPrimary
    );

    const showAlreadyExistDocument = ref(false);
    const isDocumentNumberAlreadyExists = computed(() => !!props.alreadyExistsDocumentWithDocumentNumber.id);

    return {
      showAlreadyExistDocument,
      isDocumentNumberAlreadyExists,
      hasDeclarationColor,
      isHasDeclarationDisabled,
      formattedCreatedAt,
      hasDeclaration,
      filePathUrl,
      docType,
      onChangeHasDeclaration,
      onChangeDocumentNumber,
      updateDocType,
      updateSupplierId,
      fileName,
      source,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.fontSize {
  font-size: $font-size-medium;
}

.duplicate-document-icon {
  margin: 8px;
}

.wrapper {
  width: 100%;
}

.viewer-container {
  // remove important
  height: 80vh !important;
}

.error-position {
  position: absolute;
  top: 50px;
}

.already-exist-error-link {
  cursor: pointer;
}

::v-deep .field-size {
  width: 100%;

  .el-input {
    height: 32px;
  }
  .el-input__inner {
    height: 32px;
  }

  .radio-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

::v-deep .error-border {
  .el-radio__inner {
    border-color: $error;
  }
  .el-input__inner {
    border-color: $error;
  }
}
</style>
