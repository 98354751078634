<template>
  <div>
    <h3 v-if="!loading" class="mb-4">
      {{ $tc('document.documentsOverview.classificationTasks.title') }}
    </h3>

    <TableLoadingSkeleton v-if="loading" />

    <Table
      v-if="!loading"
      v-loading="loading"
      :columns="columns"
      :data="taskedDocuments"
      show-index
      rounded
      border
      @row-click="handleRowClick"
    >
      <template #cell-uploadedBy="{ rowData: { createdBy, fromEmail } }">
        <TruncatedText>
          <template v-if="fromEmail">{{ fromEmail }}</template>
          <template v-else> {{ createdBy }}</template>
        </TruncatedText>
      </template>
    </Table>

    <DocumentModal
      v-if="previewDocumentId"
      visible
      is-upload
      :document-id="previewDocumentId"
      @close="previewDocumentId = null"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { options } from '@/locale/dateConfig';
import { Table, TruncatedText, TableLoadingSkeleton } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';
import { useCompleteTask } from '@/modules/tasks';
import { DocumentModal } from '@/modules/documentModal';
import { useUsers } from '../../payment/compositions';

import { useDocumentTasks, useUploadedDocuments } from '@/modules/document/compositions';

const TABLE_HEADERS = {
  FILE_NAME: 'fileName',
  UPLOADED_BY: 'uploadedBy',
  CREATED_AT: 'createdAt',
  SUPPLIER: 'supplier',
  SUPPLIER_CRN: 'supplierCRN',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
};

export default {
  components: { Table, TruncatedText, DocumentModal, TableLoadingSkeleton },
  setup() {
    const { currentTenant } = useTenancy();
    const businessId = computed(() => currentTenant.value.id);
    const {
      documentTasks,
      loading: tasksLoading,
      refetch: refetchTasks,
    } = useDocumentTasks(() => ({ businessId: currentTenant.value.id }));
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const { documents, loading: documentsLoading, refetch: refetchDocuments } = useUploadedDocuments(businessId);
    const { completeTask } = useCompleteTask();
    const documentCreation = ref(false);
    const isManageUser = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.manage']);
    const { users } = useUsers(
      computed(() => documents.value.filter((doc) => doc.createdBy !== null).map((doc) => doc.createdBy))
    );

    return {
      businessId,
      unrecordedDocuments: documents,
      users: computed(() => [...Object.values(users.value)]),
      uploadTasks: computed(() => documentTasks.value.filter((task) => task.type === 'upload')),
      loading: computed(() => tasksLoading.value || documentsLoading.value || documentCreation.value),
      isManageUser,
      completeTask,
      documentCreation,
      previewDocumentId: ref(null),
      refetchDocuments,
      refetchTasks,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$tc('document.documentsOverview.classificationTasks.table.file'),
          key: TABLE_HEADERS.FILE_NAME,
        },
        {
          header: this.$t('document.documentsOverview.classificationTasks.table.createdAt'),
          key: TABLE_HEADERS.CREATED_AT,
          width: '120px',
        },
        // {
        //   header: this.$t('document.documentsOverview.classificationTasks.table.uploadedBy'),
        //   key: TABLE_HEADERS.UPLOADED_BY,
        // },
        {
          header: this.$t('document.documentsOverview.classificationTasks.table.supplierName'),
          key: TABLE_HEADERS.SUPPLIER,
        },
        {
          header: this.$t('commons.companyNumber'),
          key: TABLE_HEADERS.SUPPLIER_CRN,
          width: '130px',
        },
        {
          header: this.$t('document.documentsOverview.classificationTasks.table.documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
          width: '150px',
        },
        {
          header: this.$t('document.documentsOverview.classificationTasks.table.documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
          width: '170px',
        },
      ];
    },
    taskedDocuments() {
      return this.uploadTasks.reduce((tasks, task) => {
        const documentId = task.data.documentId;
        const document = this.unrecordedDocuments?.find((doc) => doc.id === documentId);
        if (document)
          tasks.push({
            documentId,
            [TABLE_HEADERS.SUPPLIER]: document.supplier?.name ?? '-',
            [TABLE_HEADERS.SUPPLIER_CRN]: document.supplier?.number ?? '-',
            [TABLE_HEADERS.FILE_NAME]: document.filePath.split('/').pop(),
            [TABLE_HEADERS.DOCUMENT_TYPE]: document.type
              ? this.$t(`document.exports.schema.type.shortName.${document.type}`)
              : '-',
            [TABLE_HEADERS.DOCUMENT_NUMBER]: document.documentNumber ?? '-',
            [TABLE_HEADERS.CREATED_AT]: this.formatDate(task.createdAt),
            createdBy: this.getUserName(document.createdBy),
            fromEmail: task.data.fromEmail ?? document.fromEmail,
          });
        return tasks;
      }, []);
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : '';
    },
    handleRowClick(index) {
      if (this.isManageUser) {
        this.$router.push({ name: 'classifyDocuments', query: { taskNumber: index + 1 } });
      } else this.previewDocumentId = this.taskedDocuments[index].documentId;
    },
    getUserName(userId) {
      const user = this.users?.find((u) => u.id === userId);
      if (!user) return 'Unknown user';
      return `${user.firstName} ${user.lastName}`;
    },

    async handleOnSuccess() {
      await this.refetchDocuments();
      await new Promise((resolve) => setTimeout(resolve, 1500));
      await this.refetchTasks();
      this.documentCreation = false;
    },

    async handleUploadError() {
      this.documentCreation = false;
    },

    handleOnUpload() {
      this.documentCreation = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

td:not(:nth-child(1)):not(:nth-child(2)) {
  vertical-align: top;
  padding: 0.5rem;
}
.el-form-item__content {
  line-height: 1;
}
</style>
