<template>
  <div>
    <div>
      <div>
        <div class="placeholder bg-secondary file-viewer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

.file-viewer {
  width: 100%;
  height: $content-height;
}
</style>
