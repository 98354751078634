<template>
  <div>
    <div>
      <el-select v-model="selectedOption" filterable clearable @change="onOptionSelected">
        <el-option v-for="(option, index) in arrayOptions" :key="index" :label="option.name" :value="option.id">
          <div :style="{ width: optionWidthStr }">
            <TruncatedText>{{ option.name }}</TruncatedText>
          </div>
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { TruncatedText } from '@/modules/core';
import { ref } from 'vue';
export default {
  components: { TruncatedText },
  props: {
    arrayOptions: {
      type: Array,
      required: true,
    },
    optionWidth: {
      type: String,
      default: function () {
        return '200';
      },
    },
  },
  emits: ['on-apply'],
  setup(props, { emit }) {
    const selectedOption = ref(null);

    const onOptionSelected = (optionId) => {
      emit('on-apply', {
        optionId,
      });
    };

    const optionWidthStr = props.optionWidth + 'px';
    return {
      selectedOption,
      optionWidthStr,
      onOptionSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
</style>
